import 'element-plus/dist/index.css'
import { ElForm, ElTabs, ElInput, ElButton, ElTree, ElScrollbar, ElSwitch, ElDrawer, ElCascader, ElRow, ElCol, ElStatistic, ElIcon, ElDescriptions, ElTag, ElLoading, ElTable, ElPagination, ElDialog, ElConfigProvider, ElCheckbox, ElSelect, ElRadio } from 'element-plus';
export default function (app: any) {
    app.use(ElForm)
    app.use(ElInput)
    app.use(ElButton)
    app.use(ElTree)
    app.use(ElScrollbar)
    app.use(ElSwitch)
    app.use(ElDrawer)
    app.use(ElCascader)
    app.use(ElIcon)
    app.use(ElRow)
    app.use(ElCol)
    app.use(ElStatistic)
    app.use(ElDescriptions)
    app.use(ElTag)
    app.use(ElLoading)
    app.use(ElTable)
    app.use(ElPagination)
    app.use(ElConfigProvider)
    app.use(ElDialog)
    app.use(ElCheckbox)
    app.use(ElSelect)
    app.use(ElRadio)
    app.use(ElTabs)
}