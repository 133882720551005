import { createStore } from "vuex";

export default createStore({
  state: {
    nowClient: 0, //取当前视窗的最大值作为vw
    isLoading: false,
    keepAliveKey: false //更改key来强制被keepalive包裹的页面刷新
  },
  getters: {},
  mutations: {
    upNowClient(context: any, value) {
      context.nowClient = value
    },
    updateLoadingState(context: any, value) {
      context.isLoading = value
    },
    updataKeepAliveKey(context: any, value) {
      context.keepAliveKey = !context.keepAliveKey
    }
  },
  actions: {},
  modules: {

  },
});
