<template>
  <loadingCanvas v-if="store.state.isLoading" />
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import loadingCanvas from '@/components/LoadingCanvas.vue'
import { useStore } from 'vuex';
const store = useStore()
onMounted(() => {
  init();
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', forceLandscapeScreenHandle)
})
const init = () => {
  forceLandscapeScreenHandle();
  // 这里监控
  onWindowSizeChanged();
}

const forceLandscapeScreenHandle = () => {
  const body = document.getElementsByTagName('body')[0];
  const html = document.getElementsByTagName('html')[0];
  const width = html.clientWidth;
  const height = html.clientHeight;
  const max = width > height ? width : height;
  store.commit('upNowClient', max)
  const min = width > height ? height : width;
  body.style.width = max + "px";
  body.style.height = min + "px";

}

const onWindowSizeChanged = () => {
  window.addEventListener("resize", forceLandscapeScreenHandle)
}

</script>


<style lang="scss">
@import "./assets/iconfont.css";

html {
  font-size: 1vw;
  width: 100%;
  overflow-x: hidden;
}


* {
  margin: 0;
  padding: 0;
  user-select: none;
}

// ::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #9d9e9e83;
//   border-radius: 10px;
// }

@media screen and (orientation: portrait) {

  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }

  html {
    font-size: 1vh;
  }
}
</style>
