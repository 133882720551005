import { createRouter, RouteRecordRaw, createWebHashHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [

  {
    path: '/login',
    name: '登陆页',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '连邦能碳管理平台登录页'
    }
  },
  {
    path: "/sc",
    name: '连邦能碳管理平台',
    component: () => import('../views/Big_Screen/index.vue'),
    meta: {
      title: '连邦能碳管理平台'
    }
  },
  {
    path: "/admin",
    name: '连邦能碳管理平台后台',
    component: () => import('../views/Admin/pages/index.vue'),
    redirect: '/admin/group/ViewGroupManagement',
    meta: {
      title: '连邦能碳管理平台后台'
    },
    children: [
      {
        path: 'userRights', name: '用户权限', meta: { menu: 1 },
        children: [
          { path: 'ViewOrganization', name: '组织管理', meta: { menu: 2 }, component: () => import('../views/Admin/pages/userRights/Organization.vue') },
          { path: 'ViewRole', name: '角色管理', meta: { menu: 2 }, component: () => import('../views/Admin/pages/userRights/Role.vue') },
          { path: 'ViewUser', name: '用户管理', meta: { menu: 2 }, component: () => import('../views/Admin/pages/userRights/User.vue') },
        ]
      },
      {
        path: 'device', name: '设备管理', meta: { menu: 1 },
        children: [
          { path: 'ViewDeviceList', name: '设备列表', meta: { menu: 2, keepAlive: true }, component: () => import('../views/Admin/pages/device/deviceList.vue') },
          { path: 'ViewDeviceDetail', name: '设备详情', meta: { menu: 3 }, component: () => import('../views/Admin/pages/device/deviceDetail.vue') },
          { path: 'ViewEditDevice', name: '编辑设备', meta: { menu: 3 }, component: () => import('../views/Admin/pages/device/editDevice.vue') },
          { path: 'ViewAddDevice', name: '添加设备', meta: { menu: 3, keepAlive: true }, component: () => import('../views/Admin/pages/device/addDevice.vue') },
        ]
      },
      {
        path: 'group', name: '分组管理', meta: { menu: 1 },
        children: [
          { path: 'ViewGroupManagement', name: '分组列表', meta: { menu: 2, keepAlive: true }, component: () => import('../views/Admin/pages/group/groupList.vue') },
          { path: 'ViewAddGroup', name: '添加分组', meta: { menu: 3, keepAlive: true }, component: () => import('../views/Admin/pages/group/addGroup.vue') },
          { path: 'ViewEditGroup', name: '编辑分组', meta: { menu: 3 }, component: () => import('../views/Admin/pages/group/editGroup.vue') },
        ]
      },
      {
        path: 'project', name: '项目管理', meta: { menu: 1 },
        children: [
          { path: 'ViewProjectManagement', name: '项目列表', meta: { menu: 2, keepAlive: true }, component: () => import('../views/Admin/pages/project/projectList.vue') },
        ]
      },
      {
        path: 'workorder', name: '工单管理', meta: { menu: 1 },
        children: [
          { path: 'ViewWorkOrder', name: '工单列表', meta: { menu: 2, keepAlive: true }, component: () => import('../views/Admin/pages/workorder/workorderList.vue') },
          { path: 'ViewAuditWorkOrder', name: '工单审核', meta: { menu: 3 }, component: () => import('../views/Admin/pages/workorder/auditWorkOrder.vue') },
        ]
      }
    ]
  },
  {
    path: '/',
    redirect: '/sc'
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.query.title ? to.query.title as string : to.meta.title as string //动态修改在浏览器上展示的标题
  const token = localStorage.getItem('token') || ''
  if (!token && to.path != '/login') {
    next('/login')
  }
  else {
    next()
  }
})

export default router;
