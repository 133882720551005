import { createApp, provide } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import element from './utils/elementplus'
// import Particles from 'particles.vue3'

// 引入echarts
import echarts from './utils/echarts'

const app = createApp(App)
element(app)
app.use(store).use(router).mount("#app");
// 挂载到vue实例中
// Vue.prototype.$echarts = echarts;//vue2的挂载方式
app.config.globalProperties.$echarts = echarts;//vue3的挂载方式
